.editservice {
  padding-bottom: 80px;
  width: 100%;
}

.editservice_topnav {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  color: rgb(0, 0, 0);
  position: fixed;
  top: 0;
  z-index: 9999999999999999;
}

.editservice_topnav_back {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.editservice_topnav_promote {
  padding-left: 14px;
  padding-right: 14px;
  height: 45px;
  background-color: #f58700;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.editservice_body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.editservice_body_videopresenter {
  width: calc(100vw - 28px);
  height: calc(100% - 160px);
  object-fit: cover;
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 1;
  border-radius: 24px;
  margin-left: 14px;
}

.editservice_body_videorecorder {
  width: calc(100vw);
  height: calc(100% - 40px);
  object-fit: cover;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 1;
  border-radius: 24px;
}

.editservice_videosrow {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100px;
  width: 100%;
  padding-left: 14px;
  gap: 10px;
}

.editservice_videosrow_video {
  width: 60px;
  height: 60px;
  background-color: #f3f3f3;
  display: fleX;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #ffffff;
}

.editservice_videosrow_videoactive {
  width: 60px;
  height: 60px;
  background-color: #f3f3f3;
  display: fleX;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #f58700;
}

.editservice_downloadbutton {
  position: absolute;
  bottom: 120px;
  right: 30px;
  z-index: 9999;
  background-color: white;
  border-radius: 999px;
  width: 45px;
  height: 45px;
  display: fleX;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.editservice_editbutton {
  position: absolute;
  bottom: 185px;
  right: 30px;
  z-index: 9999;
  background-color: white;
  border-radius: 999px;
  width: 45px;
  height: 45px;
  display: fleX;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.editservice_body_videorecorder_actionbar {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.editservice_body_videorecorder_actionbar_uploadButton {
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.editservice_body_videorecorder_actionbar_recordButton {
  width: 60px;
  height: 60px;
  background-color: red;
  border: 2px solid white;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.editservice_body_videorecorder_actionbar_deleteButton {
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.editservice_body_navbar {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 14px;
  padding-right: 14px;
  margin-top: 50vh;
}

.editservice_body_navbar_videos {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.editservice_body_navbar_videos_video {
  width: 33.3%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.editservice_body_navbar_videos_video_active {
  width: 33.3%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f58700;
}

.editservice_body_navbar_next {
  width: 70px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 111, 0);
  border-radius: 9999px;
}

.editservice_body_description {
  padding: 14px;
  font-weight: 500;
  font-size: 24px;
  position: fixed;
  margin-top: 50vh;
  color: white;
  z-index: 9999999;
}

.editservice_body_resetbtn {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: white;
  box-shadow: 0px 1px 12px 2px #00000022;
  border-radius: 9999px;
  top: 80px;
  right: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}

.editservice_body_startrecording {
  position: absolute;
  width: 300px;
  height: 60px;
  background-color: #f58700;
  color: black;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0px 1px 12px 2px #00000022;
  border-radius: 9999px;
  bottom: 120px;
  right: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}

.editservice_body_influencervideo {
  position: absolute;
  width: calc(100vw - 140px);
  height: 60px;
  background-color: white;
  color: black;
  box-shadow: 0px 1px 12px 2px #00000022;
  border-radius: 24px;
  top: 80px;
  left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}

.editservice_body_editrecording {
  position: absolute;
  width: 300px;
  height: 60px;
  background-color: #ffffff;
  color: #f58700;
  font-size: 16px;
  box-shadow: 0px 1px 12px 2px #00000022;
  border-radius: 9999px;
  bottom: 120px;
  right: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}

.editservice_body_overlaytext {
  padding: 24px;
  background-color: #0000004a;
  border-radius: 24px;
  position: absolute;
  width: calc(100% - 48px);
  margin-left: 24px;
  top: 60px;
  font-weight: 600;
  color: white;
  z-index: 99999999999999999999;
}

.editservice_body_playPauseButton {
  width: 50px;
  height: 50px;
  background-color: #0000008e;
  border-radius: 9999px;
  position: absolute;
  top: 160px;
  right: 30px;
  font-weight: 600;
  color: white;
  z-index: 99999999999999999999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
