.home {
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
}

.home_topnav {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  border-bottom: 1px solid #dddddd;
  background-color: white;
  z-index: 99999;
}

.home_topnav_logo {
  width: calc(100% - 60px);
  padding-right: 100px;
  height: auto;
  object-fit: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
}

.home_topnav_logo_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
}

.home_topnav_left {
  width: calc(100% - 60px);
  height: 50px;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  color: #f58700;
  font-weight: 700;
  margin-left: -10px;
}

.home_topnav_right {
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.home_body {
  width: 100%;
  padding-top: 12px;
}

.home_body_list {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-left: 10px;
  padding-right: 10px;
  gap: 10px;
}

.home_body_list_listelement {
  width: calc(50% - 5px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.home_body_list_listelement_top {
  background-color: grey;
  width: 100%;
  height: auto;
  height: 220px;
  border-radius: 12px;
}

.home_body_list_listelement_overlay {
  background-color: rgba(255, 255, 255, 0.684);
  width: calc(50vw - 40px);
  margin-bottom: 10px;
  position: absolute;
  height: 40px;
  border-radius: 999px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
}
