.create {
  padding-top: 40px;
  padding-bottom: 80px;
  width: 100%;
}

.create_topnav {
  width: 100%;
  height: 40px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  border-bottom: 1px solid #dddddd;
  background-color: white;
  z-index: 999999;
}

.create_topnav_back {
  width: 80px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.create_topnav_save {
  width: 80px;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f58700;
  color: black;
  font-weight: 600;
  border-radius: 16px;
}

.create_topnav_save_blurred {
  width: 80px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  opacity: 0.5;
}

.create_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 60px;
}

.create_body_video {
}

.create_body_bottombar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  gap: 10px;
  padding-left: 14px;
  overflow-x: auto; /* Allow horizontal scrolling */
  overflow-y: hidden; /* Hide vertical scrollbar if any */
  flex-wrap: nowrap;
  white-space: nowrap; /* Prevent flex children from wrapping onto new lines */
}

.create_body_bottombar_element {
  width: 50px;
  height: 65px;
  background-color: #f3f3f3;
  border-radius: 8px;
  flex-shrink: 0; /* Prevent the element from shrinking */
  border: 2px solid white;
}

.create_body_bottombar_element_active {
  width: 50px;
  height: 65px;
  background-color: #f3f3f3;
  border-radius: 8px;
  flex-shrink: 0; /* Prevent the element from shrinking */
  border: 2px solid #f58700;
}

.create_body_bottombar_element_img {
  width: 46px;
  height: 61px;
  object-fit: cover;
  border-radius: 4px;
}

.create_body_bottombar_addbutton {
  width: 50px;
  height: 65px;
  background-color: #f3f3f3;
  border-radius: 8px;
  flex-shrink: 0; /* Prevent the element from shrinking */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
